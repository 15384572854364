<script setup>
import Header from "@/components/header/index.vue";
import Footer from "@/components/footer/index.vue";
import zhCn from "element-plus/es/locale/lang/zh-cn";
import { userLoginApi } from "@/api/login.js";
import {
  setToken,
  setClientKey,
  removeToken,
  removeClientKey,
  setExpireKey,
  getExpireKey,
} from "@/utils/auth.js";
import Cookies from "js-cookie";

const showPage = ref(false);
const userLogin = () => {
  if (
    getExpireKey() &&
    Math.round(new Date() / 1000) < Number(getExpireKey())
  ) {
    showPage.value = true;
    return;
  }
  const params = {
    username: "test",
    grantType: "password",
    tenantId: "000000",
    clientId: "e5cd7e4891bf95d1d19206ce24a7b32e",
  };
  userLoginApi(params)
    .then((res) => {
      if (res.code === 200) {
        setToken(res.data.access_token);
        setClientKey(res.data.client_id);
        setExpireKey();
        Cookies.set("user", JSON.stringify(res.data.hqUser));
        showPage.value = true;
      } else {
        ElMessage.error("服务异常，请稍后再试!");
        removeToken();
        removeClientKey();
        showPage.value = true;
      }
    })
    .catch((err) => {
      removeToken();
      removeClientKey();
      showPage.value = true;
    });
};
userLogin();

let initialWidth = Cookies.get('initialWidth') ? Cookies.get('initialWidth') : window.innerWidth;
let initialHeight = window.innerHeight;
// window.addEventListener("resize", () => {
//   if (window.innerWidth !== initialWidth || window.innerHeight !== initialHeight) {
//     window.resizeTo(initialWidth, initialHeight);
//   }
// });

const resizeFn = () => {
  if (window.innerWidth <= initialWidth) {
    document.querySelector("html").style.width = initialWidth + 'px';
    document.querySelector("html").style.overflowX = "hidden";
    document.querySelector("html").style.transformOrigin = "0 0";
    document.querySelector("html").style.transform = `scale(${(
      window.innerWidth / initialWidth
    ).toFixed(2)})`;
  } else if (window.innerWidth >= 1920) {
    document.querySelector("html").style.width = "1920px";
    document.querySelector("html").style.overflowX = "hidden";
    document.querySelector("html").style.transformOrigin = "0 0";
    document.querySelector("html").style.transform = `scale(${(
      window.innerWidth / 1920
    ).toFixed(2)})`;
  } else {
    document.querySelector("html").style = {};
  }
};

onMounted(() => {
  if (!Cookies.get('initialWidth')) {
    Cookies.set('initialWidth', initialWidth)
  };
  resizeFn()
  window.addEventListener('resize',resizeFn)
});

onUnmounted(() => {
  window.removeEventListener('resize',resizeFn)
});
</script>
<template>
  <div class="app-container">
    <el-config-provider :locale="zhCn">
      <Header></Header>
      <router-view v-if="showPage" :locale="zhCn" />
      <Footer></Footer>
    </el-config-provider>
  </div>
</template>
