import { createApp } from 'vue'
import './style.css'
import App from './App.vue'
import router from './router/index'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

// createApp(App).mount('#app')
app.use(router)
app.use(ElementPlus)
app.mount('#app');

// 检测到路由跳转后，将页面滚动到顶部
router.beforeEach((to, from, next) => {
  // 获取到前面打过标签的元素，将其scrollTop属性设置为0
  document.documentElement.scrollTop = 0;
  // 滚动条可能出现在HTML元素上而不是body上，确保兼容性，同时将HTML和body中scrollTop属性设置为0
  document.body.scrollTop = 0;
  next();
});
