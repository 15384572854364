<script setup>
import { getLiveDetailApi, watchHistoryApi } from "../../api/live.js";
import Breadcrumb from "../../components/breadcrumb/index.vue";
import { ElMessage } from "element-plus";
import { nextTick, onMounted } from "vue";
import { useRoute } from "vue-router";
import LivePlayer from "@liveqing/liveplayer-v3"; // vue3
const route = useRoute();

const liveId = route.query.id;
const isPause = ref(true);
const liveUrl = ref("");
const liveDetail = ref({});
const messages = ref([
  { text: "12312312", name: "sdasd" },
  { text: "121sadsa2121", name: "sdas2132131d1" },
  { text: "1212csdfge121", name: "sdasd343432cc" },
  { text: "12e23edsadew12121", name: "sdastrgerfwd" },
  { text: "121asddsvG2121", name: "s123fdsdasd" },
  { text: "121TRGERFA2121", name: "sda213hasdsd" },
]);
const isScrollAtBottom = ref(true);
const textarea = ref("");
const chatBox = ref(null);
const timer = ref(null);

// 获取直播详情
const getLiveDetail = () => {
  getLiveDetailApi(liveId).then((res) => {
    if (res.code === 200) {
      liveDetail.value = res.data;
      liveDetail.value.livePictureAddress = JSON.parse(liveDetail.value?.livePictureAddress)[0];
      liveUrl.value = res.data?.cameraList[0]?.transcodeList[0]?.urlHls;
      if (res.data.liveState === "1") {
        isPause.value = false;
        clearInterval(timer.value);
        timer.value = null;
      }
    }
  });
};

// 聊天窗口滚动监听
const handleScroll = (e) => {
  const { scrollTop, scrollHeight, clientHeight } = event.target;
  isScrollAtBottom.value = scrollHeight - (scrollTop + clientHeight) < 1; // 判断是否滚动到底部
};
// 用户发送聊天信息
const addMessage = () => {
  if (!textarea.value) return;
  const obj = {
    name: "严嵩",
    text: textarea.value,
  };
  messages.value.push(obj);
  if (isScrollAtBottom.value) {
    nextTick(() => {
      textarea.value = "";
      const container = chatBox.value;
      container.scrollTop = container.scrollHeight;
    });
  }
};

// 点赞
const handleLike = () => {
  ElMessage.success("点赞成功");
};

// 创建浏览记录
const watchHistory = () => {
  const params = {
    id: liveId,
    userId: '23143214',
  };
  watchHistoryApi(params);
};

onMounted(() => {
  timer.value = setInterval(() => {
    getLiveDetail();
  }, 10000);
  getLiveDetail();
  watchHistory();
});
</script>

<template>
  <div class="page-container">
    <Breadcrumb name="直播详情"></Breadcrumb>
    <div class="page-content">
      <div class="page-main">
        <div class="main-left">
          <div class="main-left-header">
            <div class="header-title">{{ liveDetail.title }}</div>
            <div class="header-detail flexRow aICenter">
              <div class="flexRow aICenter">
                <img src="@/assets/icon/icon_eye_grey.png" alt="" />
                <span>1234</span>
              </div>
              <div>主播：{{ liveDetail.anchorName }}</div>
              <div>时间：2024年1月14日 19:00</div>
            </div>
          </div>
          <div class="main-left-player">
            <LivePlayer :videoUrl="liveUrl" fluent live v-if="!isPause"></LivePlayer>
            <template v-else>
              <img class="main-left-player-cover" :src="liveDetail.livePictureAddress?.url" alt="">
              <div class="main-left-player-pause">直播暂停中...</div>
            </template>
          </div>

          <div class="main-left-desc">
            <div class="title flexRow aICenter">
              <div class="cube"></div>
              <div class="title-txt">直播详情</div>
            </div>
            <div class="desc-content">{{ liveDetail.details }}</div>
          </div>
        </div>
        <div class="main-right">
          <div class="main-right-chat" ref="chatBox" @scroll="handleScroll">
            <div class="main-right-chat-messages">
              <div class="main-right-chat-warn">
                系统提示：直播内容及互动评论须严格遵守直播规范，严禁传播违法违规、低俗血暴、吸烟酗酒、造谣诈骗等不良有害信息。如有违规，平台将对违规直播间或账号进行相应的处罚！
              </div>
              <div
                class="main-right-chat-message"
                v-for="(item, index) in messages"
                :key="index"
              >
                {{ item.name + ":" }} {{ item.text }}
              </div>
            </div>
          </div>
          <el-input
            v-model="textarea"
            maxlength="20"
            style="width: 100%"
            show-word-limit
            type="textarea"
            :rows="3"
            placeholder="发个评论呗~"
          />
          <div class="main-right-operate flexRow jCBetween">
            <div class="like-btn flexRow aICenter" @click="handleLike">
              <img src="@/assets/icon/icon_good.png" />
              <span>点赞</span>
            </div>
            <div class="send-btn" @click="addMessage">发送</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.page-container {
  width: 100%;
  margin: 0 auto;
  padding-bottom: 70px;
  max-width: 1920px;
  background: #f5f5f7;
  min-height: 870px;
  .breadcrumb {
    width: 100%;
    margin: 0 auto;
    height: 37px;
    max-width: 1920px;
    background: #fff;
    display: flex;
    align-items: center;
    .breadcrumb_content {
      width: 1200px;
      margin: 0 auto;
      font-weight: 400;
      font-size: 12px;
      color: #666666;
      line-height: 14px;
      span {
        cursor: pointer;
      }
      img {
        width: 16px;
        height: 16px;
      }
      .arrow {
        width: 6px;
        height: 9px;
        margin: 0 10px;
      }
    }
  }
  .page-content {
    width: 1200px;
    margin: 0 auto;
    padding-top: 20px;
    box-sizing: border-box;
    .page-main {
      width: 100%;
      height: 503px;
      display: flex;
      margin-bottom: 60px;
      .main-left {
        width: 895px;
        height: 100%;
        position: relative;
        margin-right: 20px;
        .main-left-header {
          width: 100%;
          height: 92px;
          background-color: #fff;
          padding: 20px 20px 0;
          .header-title {
            font-weight: 600;
            font-size: 20px;
            color: #1f1f1f;
            line-height: 23px;
            margin-bottom: 4px;
          }
          .header-detail {
            display: flex;
            align-items: center;
            font-weight: 400;
            font-size: 14px;
            color: #999999;
            line-height: 16px;
            > div {
              margin-right: 20px;
              margin-top: 4px;
            }
            img {
              width: 16px;
              height: 16px;
              margin-right: 4px;
            }
          }
        }
        .main-left-player {
          width: 100%;
          height: 100%;
          position: relative;
          background-color: #eee;
          .main-left-player-cover {
            width: 100%;
            height: 100%;
          }
          .main-left-player-pause {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            padding: 30px 50px;
            border-radius: 20px;
            background-color: rgba(0, 0, 0, 0.3);
            color: #fff;
            font-size: 28px;
            font-weight: 500;
          }
        }
        video {
          width: 100%;
          height: 100%;
        }
        .main-left-desc {
          padding: 20px 20px 30px;
          background-color: #fff;
          .title-txt {
            margin-left: 10px;
          }
          .desc-content {
            margin-top: 10px;
            font-weight: 400;
            font-size: 14px;
            color: #1f1f1f;
            line-height: 16px;
          }
        }
      }
      .main-right {
        flex: 1;
        height: 595px;
        background: #fff;
        padding: 20px 15px;
        box-sizing: border-box;
        .main-right-chat {
          width: 100%;
          height: 432px;
          background: #f2f2f2;
          padding: 10px;
          overflow-y: scroll;
          margin-bottom: 15px;
          .main-right-chat-warn {
            font-weight: 400;
            font-size: 12px;
            color: #fa9550;
            line-height: 14px;
            margin-bottom: 10px;
          }
          .main-right-chat-message {
            font-weight: 400;
            font-size: 12px;
            color: #999999;
            line-height: 14px;
            margin-bottom: 8px;
          }
        }
        .main-right-operate {
          margin-top: 10px;
          font-weight: 400;
          font-size: 12px;
          color: #999999;
          line-height: 14px;
          .like-btn {
            cursor: pointer;
            img {
              width: 24px;
              height: 24px;
            }
          }
          .send-btn {
            width: 78px;
            height: 25px;
            background: #1890ff;
            border-radius: 2px;
            font-weight: 400;
            font-size: 12px;
            color: #ffffff;
            text-align: center;
            line-height: 25px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
