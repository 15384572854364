<script setup>
import { computed, watch, ref } from "vue";
import { ElMessage } from "element-plus";
import { getToken, getClientKey } from "@/utils/auth";

const emit = defineEmits(['update:modelValue'])
const props = defineProps({
  modelValue: [String, Object, Array],
  // 文件数量限制
  limit: {
    type: Number,
    default: 5,
  },
  // 是否禁用
  disabled: {
    type: Boolean,
    default: false
  },
  // 大小限制(MB)
  fileSize: {
    type: Number,
    default: 20,
  },
  // 文件类型, 例如['png', 'jpg', 'jpeg']
  fileType: {
    type: Array,
    default: () => ["png", "jpg", "jpeg"],
  },
  // 是否显示提示
  isShowTip: {
    type: Boolean,
    default: true,
  },
  // + 号图标下面的文案
  tipsText: {
    type: String,
    default: "",
  },
});

const number = ref(0);
const uploadList = ref([]);
const dialogFileUrl = ref("");
const dialogVisible = ref(false);
const baseUrl = ref(process.env.VITE_TINTMCE_BASE_URL);
const uploadFileUrl = ref("resource/oss/upload");
// token暂时写死
const clientid = getClientKey();
const token = getToken();;
const headers = {
  Authorization: "Bearer " + token,
  clientId: clientid,
};
const fileList = ref([]);
const imageUpload = ref(null);
const loadingInstance = ref(null);

watch(
  () => props.modelValue,
  (val) => {
    if (val) {
      fileList.value = Array.isArray(val) ? val : [];
    } else {
      fileList.value = [];
      return [];
    }
  }
);

const showTip = computed(() => {
  return props.isShowTip && (props.fileType || props.fileSize);
});

// 上传前loading加载
const handleBeforeUpload = (file) => {
  let isImg = false;
  if (props.fileType.length) {
    let fileExtension = "";
    if (file.name.lastIndexOf(".") > -1) {
      fileExtension = file.name.slice(file.name.lastIndexOf(".") + 1);
    }
    isImg = props.fileType.some((type) => {
      if (file.type.indexOf(type) > -1) return true;
      if (fileExtension && fileExtension.indexOf(type) > -1) return true;
      return false;
    });
  } else {
    isImg = file.type.indexOf("image") > -1;
  }
  
  if (!isImg) {
    ElMessage.error(`文件格式不正确, 请上传${props.fileType.join("/")}格式文件!`)
    return false;
  }
  
  if (props.fileSize) {
    const isLt = file.size / 1024 / 1024 < props.fileSize;
    if (!isLt) {
      ElMessage.error(`上传文件大小不能超过 ${props.fileSize} MB!`)
      return false;
    }
  }
  number.value++;
};

// 文件个数超出
const handleExceed = () => {
  ElMessage.error(`上传文件数量不能超过 ${props.limit} 个!`)
};

// 上传成功回调
const handleUploadSuccess = (res, file) => {
  if (res.code === 200) {
    uploadList.value.push({
      name: res.data.fileName,
      size: file.size,
      mimeType: res.data.mimeType,
      ossId: res.data.ossId,
      url: res.data.url,
    });
    uploadedSuccessfully();
  } else {
    number.value--;
    ElMessage.error(res.msg)
    imageUpload.handleRemove(file);
    uploadedSuccessfully();
  }
};

// 删除文件
const handleDelete = (file) => {
  fileList.value = [];
  emit("update:modelValue", fileList.value);
};

// 上传失败
const handleUploadError = (res) => {
  ElMessage.error('上传失败，请重试')
};

// 上传结束处理
const uploadedSuccessfully = () => {
  if (number.value > 0 && uploadList.value.length === number.value) {
    fileList.value = fileList.value.concat(uploadList.value);
    uploadList.value = [];
    number.value = 0;
    emit("update:modelValue", fileList.value);
  }
};

// 预览
const handlePictureCardPreview = (file) => {
  if (checkFileIsPDF(file.url)) {
    window.open(file.url, "_blank");
  } else {
    dialogFileUrl.value = file.url;
    dialogVisible.value = true;
  }
};

// 对象转成指定字符串分隔
const listToString = (list, separator) => {
  let strs = "";
  separator = separator || ",";
  for (let i in list) {
    if (undefined !== list[i].url && list[i].url.indexOf("blob:") !== 0) {
      strs += list[i].url + separator;
    }
  }
  return strs != "" ? strs.substr(0, strs.length - 1) : "";
};

/** 效验文件类型-文件 */
const checkFileIsPDF = (fileName) => {
  let suffix = fileName.split(".").pop().toLowerCase();
  let fileType = ".pdf";
  return fileType.includes(suffix);
};
</script>

<template>
  <div class="component-upload-image">
    <el-upload
      multiple
      :action="baseUrl + uploadFileUrl"
      list-type="picture-card"
      :on-success="handleUploadSuccess"
      :before-upload="handleBeforeUpload"
      :limit="props.limit"
      :on-error="handleUploadError"
      :on-exceed="handleExceed"
      ref="imageUpload"
      :on-remove="handleDelete"
      :show-file-list="true"
      :headers="headers"
      :file-list="fileList"
      :on-preview="handlePictureCardPreview"
      :disabled="disabled"
      :class="{ hide: fileList.length >= props.limit }"
    >
      <el-icon class="avatar-uploader-icon">
        <plus />
      </el-icon>
      <p class="tips-text" v-if="tipsText">{{ tipsText }}</p>
      <template #file="{ file }">
        <div
          class="item-file flexCenter"
          style="width: 100%; height: 100%; padding: 20px"
          v-if="false"
        >
          <!-- <custom-image
            src="/images/common/file-icon-pdf.png"
            fit="contain"
          ></custom-image> -->
          <label class="el-upload-list__item-status-label">
            <el-icon color="#fff">
              <Check />
            </el-icon>
          </label>
          <span class="el-upload-list__item-actions">
            <span class="el-upload-list__item-preview">
              <el-icon color="#fff" @click="handlePictureCardPreview(file)"
                ><Zoom-In
              /></el-icon>
            </span>
            <span class="el-upload-list__item-preview">
              <el-icon color="#fff" @click="handleDelete(file)"
                ><Delete
              /></el-icon>
            </span>
          </span>
        </div>
      </template>
    </el-upload>

    <!-- 上传提示 -->
    <div class="el-upload__tip" slot="tip" v-if="showTip">
      请上传
      <template v-if="fileSize">
        大小不超过 <b style="color: #f56c6c">{{ fileSize }}MB</b></template
      >
      <template v-if="fileType">
        格式为 <b style="color: #f56c6c">{{ fileType.join("/") }}</b></template
      >
      的文件
    </div>

    <el-dialog v-model="dialogVisible" title="预览" width="800" append-to-body align-center>
      <img
        :src="dialogFileUrl"
        style="display: block; max-width: 100%; margin: 0 auto"
        alt=""
      />
    </el-dialog>
  </div>
</template>

<style lang="scss" scoped></style>
