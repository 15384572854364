<script setup>
import {
  getVideoListApi,
  getCategotyListApi,
  getVideoListByIntegratedApi,
  getVideoListByPlayAmountApi,
  getVideoListByLikesAmountApi,
} from "../../api/video.js";
import { useRouter } from "vue-router";
import { nextTick } from "vue";
import arrow_right from '@/assets/video/arrow_right.png';
import arrow_down from '@/assets/video/arrow_down.png';
const router = useRouter();
const loading = ref(true);
const selectId = ref("");
const nav = ref([]);
const sortList = ref([
  { name: "综合排序", id: "" },
  { name: "最多播放", id: "1" },
  { name: "最新发布", id: "2" },
  { name: "最多点赞", id: "3" },
]);
const sortType = ref("");
const keyword = ref("");
const videoList = ref([]);
const total = ref(0);
const pageNum = ref(1);
const query = ref({
  pageNum: pageNum.value,
  pageSize: 12,
  title: keyword.value,
  categoryId: selectId.value,
});

// 获取列表数据
const getVideoList = () => {
  loading.value = true;
  if (sortType.value === "") {
    getVideoListByIntegratedApi(query.value).then((res) => {
      let data = res.rows;
      if (data && data.length > 0) {
        data.map((v) => {
          v.coverImageUrl = JSON.parse(v.coverImageUrl)[0];
        });
        videoList.value = data;
      } else {
        videoList.value = [];
      };
      total.value = res.total;
      loading.value = false;
    });
  } else if (sortType.value === "1") {
    getVideoListByPlayAmountApi(query.value).then((res) => {
      res.rows.map((v) => {
        v.coverImageUrl = JSON.parse(v.coverImageUrl)[0];
      });
      videoList.value = res.rows;
      total.value = res.total;
      loading.value = false;
    });
  } else if (sortType.value === "2") {
    getVideoListApi(query.value).then((res) => {
      res.rows.map((v) => {
        v.coverImageUrl = JSON.parse(v.coverImageUrl)[0];
      });
      videoList.value = res.rows;
      total.value = res.total;
      loading.value = false;
    });
  } else if (sortType.value === "3") {
    getVideoListByLikesAmountApi(query.value).then((res) => {
      res.rows.map((v) => {
        v.coverImageUrl = JSON.parse(v.coverImageUrl)[0];
      });
      videoList.value = res.rows;
      total.value = res.total;
      loading.value = false;
    });
  }
};

// 获取推荐列表
const recommendList = ref([]);
const recommendVideoList = () => {
  const params = {
    pageNum: 1,
    pageSize: 3,
    recommended: 1,
  };
  getVideoListApi(params).then((res) => {
    let data = res.rows;
    if (data && data.length > 0) {
      data.map((v) => {
        v.coverImageUrl = JSON.parse(v.coverImageUrl)[0];
      });
      nextTick(() => {
        recommendList.value = data;
      });
    };
  });
};

// 获取分类数据
const getCategotyList = () => {
  getCategotyListApi().then((res) => {
    nav.value = res.data;
    nav.value.unshift({
      name: '全部分类',
      id: ''
    });
  });
};

// 选择一级分类
const handleOpen = (item) => {
  if (!item.id) {
    handleSelect(item);
  } else {
    item.isActive = !item.isActive;
  }
};

// 选择二级分类
const handleSelect = (item) => {
  if (item.id === selectId.value) return;
  nav.value.map((v) => {
    if (v.children && v.children.length > 0) {
      v.children.map((m) => {
        m.isSelected = false;
      });
    }
  });
  item.isSelected = true;
  selectId.value = item.id;
  query.value.categoryId = selectId.value;
  query.value.pageNum = 1;
  getVideoList();
};

// 排序切换
const handleSort = (id) => {
  sortType.value = id;
  query.value.pageNum = 1;
  getVideoList();
};

// 搜索
const handleSearch = () => {
  query.value.title = keyword.value;
  query.value.pageNum = 1;
  getVideoList();
};

// 分页跳转
const handleChange = (e) => {
  query.value.pageNum = e;
  getVideoList();
};

// 跳转详情
const openDetail = (id) => {
  router.push("/videoCenter/videoDetail?id=" + id);
};

onMounted(() => {
  getVideoList();
  getCategotyList();
  recommendVideoList();
});
</script>

<template>
  <div>
    <div class="page-video">
      <div class="video-banner">
        <div class="banner-left" @click="openDetail(recommendList[0].id)">
          <img v-if="recommendList[0]?.coverImageUrl?.url" :src="recommendList[0]?.coverImageUrl?.url" />
          <div v-else class="banner-left-bg"></div>
          <section v-if="recommendList[0]?.title">
            <div class="title">{{ recommendList[0]?.title }}</div>
            <div class="subtitle">{{ recommendList[0]?.subtitle }}</div>
          </section>
          <section v-else>
            <div class="title">暂无内容</div>
            <div class="subtitle">暂无内容</div>
          </section>
        </div>
        <div class="banner-right">
          <div
            class="banner-right-item"
            @click="openDetail(recommendList[1].id)"
          >
            <img v-if="recommendList[1]?.coverImageUrl?.url" :src="recommendList[1]?.coverImageUrl?.url" />
            <div v-else class="banner-right-bg"></div>
            <section v-if="recommendList[1]?.title">
              <div class="title">{{ recommendList[1]?.title }}</div>
              <div class="subtitle">{{ recommendList[1]?.subtitle }}</div>
            </section>
            <section v-else>
              <div class="title">暂无内容</div>
              <div class="subtitle">暂无内容</div>
            </section>
          </div>
          <div
            class="banner-right-item"
            @click="openDetail(recommendList[2].id)"
          >
            <img v-if="recommendList[2]?.coverImageUrl?.url" :src="recommendList[2]?.coverImageUrl?.url" />
            <div v-else class="banner-right-bg"></div>
            <section v-if="recommendList[2]?.title">
              <div class="title">{{ recommendList[2]?.title }}</div>
              <div class="subtitle">{{ recommendList[2]?.subtitle }}</div>
            </section>
            <section v-else>
              <div class="title">暂无内容</div>
              <div class="subtitle">暂无内容</div>
            </section>
          </div>
        </div>
      </div>
    </div>
    <div class="video-content">
      <div class="content-main">
        <div class="nav">
          <div class="nav-item" :class="selectId === item.id ? 'active' : ''" v-for="(item, index) in nav" :key="index">
            <div class="flexRow aICenter" @click="handleOpen(item)">
              <img
                v-if="item.id"
                :src="
                  item.isActive
                    ? arrow_down
                    : arrow_right
                "
              />
              <span>{{ item.name }}</span>
            </div>
            <template v-if="item.isActive">
              <div
                class="nav-item-child"
                :class="itm.id === selectId ? 'active' : ''"
                v-for="(itm, idx) in item.children"
                :key="idx"
                @click="handleSelect(itm)"
              >
                {{ itm.name }}
              </div>
            </template>
          </div>
        </div>
        <div class="list">
          <div class="list-header flexRow aICenter">
            <div class="header-sort flexRow">
              <div
                class="sort-item"
                :class="sortType === item.id ? 'sort-active' : ''"
                v-for="(item, index) in sortList"
                :key="index"
                @click="handleSort(item.id)"
              >
                {{ item.name }}
              </div>
            </div>
            <div class="header-search">
              <el-input
                class="header-search-ipt"
                type="text"
                v-model="keyword"
                placeholder="请输入内容"
                @keyup.enter="handleSearch"
              ></el-input>
              <div class="header-search-btn" @click="handleSearch">
                <img src="@/assets/icon/icon_search.png" />
                <span>搜索</span>
              </div>
            </div>
          </div>
          <template v-if="videoList.length">
            <div class="list-content" v-loading="loading">
              <div
                class="list-item"
                v-for="(item, index) in videoList"
                :key="index"
                @click="openDetail(item.id)"
              >
                <div class="list-item-img">
                  <img :src="item.coverImageUrl?.url" alt="" />
                  <div class="isRecommend" v-if="item.recommended === 1">
                    <img src="@/assets/icon/icon_fire.png">
                    推荐
                  </div>
                  <div class="list-item-count flexRow">
                    <div class="flexRow aICenter">
                      <img src="@/assets/index/icon_eye.png" />
                      <span>{{ item.views }}</span>
                    </div>
                    <div class="flexRow aICenter">
                      <img src="@/assets/icon/icon_like.png" />
                      <span>{{ item.likes }}</span>
                    </div>
                  </div>
                </div>
                <div class="list-item-info">
                  <span class="desc text_line_2">{{ item.title }}</span>
                </div>
              </div>
            </div>
            <div class="pagination">
              <el-pagination
                :background="true"
                layout="total, prev, pager, next"
                :total="total"
                :page-size="12"
                @change="handleChange"
              />
            </div>
          </template>
          <el-empty v-else description="暂无内容"></el-empty>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
:deep(.el-input__wrapper) {
  border: none;
  outline: none;
  box-shadow: none;
}
:deep(.el-input__wrapper:hover) {
  border: none;
  outline: none;
  box-shadow: none;
}
:deep(.el-input__wrapper.is-focus) {
  border: none;
  outline: none;
  box-shadow: none;
}
@keyframes scale {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.05);
  }
}
.page-video {
  width: 1200px;
  max-width: 1920px;
  margin: 0 auto;
  padding-top: 84px;
  margin-bottom: 30px;
  .video-banner {
    display: flex;
    .banner-left {
      flex: 1;
      height: 409px;
      background: #ffffff;
      box-shadow: 0px 4px 4px 0px rgba(18, 74, 163, 0.15);
      border: 1px solid #f6f6f6;
      position: relative;
      padding: 4px;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
      section {
        width: calc(100% - 8px);
        height: 80px;
        background: rgba(44, 60, 111, 0.6);
        position: absolute;
        bottom: 4px;
        left: 4px;
        padding: 10px 10px 0;
      }
      .banner-left-bg {
        width: 400px;
        height: 400px;
        margin: 0 auto;
        background-image: url(@/assets/index/no_source.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center;
        cursor: auto;
      }
    }
    .banner-left:hover {
      animation: scale 0.2s linear forwards;
    }
    .banner-right {
      width: 466px;
      margin-left: 24px;
      .banner-right-item {
        width: 100%;
        height: 194px;
        background: #ffffff;
        box-shadow: 0px 4px 4px 0px rgba(18, 74, 163, 0.15);
        border: 1px solid #f6f6f6;
        padding: 4px;
        margin-bottom: 20px;
        position: relative;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
        section {
          width: calc(100% - 8px);
          height: 68px;
          background: rgba(44, 60, 111, 0.6);
          position: absolute;
          bottom: 4px;
          left: 4px;
          padding: 10px 10px 0;
        }
        .banner-right-bg {
          width: 180px;
          height: 180px;
          margin: 0 auto;
          background-image: url(@/assets/index/no_source.png);
          background-size: 100% 100%;
          background-repeat: no-repeat;
          background-position: center;
          cursor: auto;
        }
      }
      .banner-right-item:hover {
        animation: scale 0.2s linear forwards;
      }
      .banner-right-item:last-child {
        margin-bottom: 0;
      }
    }
    .title {
      font-weight: 600;
      font-size: 20px;
      color: #ffffff;
      line-height: 23px;
    }
    .subtitle {
      font-weight: 400;
      font-size: 16px;
      color: #ffffff;
      line-height: 19px;
      margin-top: 10px;
    }
  }
}
.video-content {
  width: 100%;
  max-width: 1920px;
  max-height: 1394px;
  background-image: url("@/assets/video/video_bg.png");
  background-size: 100% 1394px;
  background-position: center;
  padding-bottom: 50px;
  .content-main {
    width: 1200px;
    margin: 0 auto;
    padding-top: 20px;
    display: flex;
    .nav {
      width: 183px;
      height: 515px;
      background-image: url("@/assets/video/video_nav_bg.png");
      background-size: 100% 100%;
      background-position: center;
      margin-right: 20px;
      padding: 20px 10px 0;
      box-shadow: 0px 4px 4px 0px rgba(18, 74, 163, 0.15);
      border: 1px solid #f6f6f6;
      margin-bottom: 30px;
      .nav-item {
        padding-left: 10px;
        line-height: 32px;
        margin-bottom: 10px;
        font-weight: 400;
        font-size: 16px;
        color: #1f1f1f;
        cursor: pointer;
        img {
          width: 16px;
          height: 16px;
          margin-right: 10px;
        }
        .nav-item-child {
          width: 100%;
          height: 32px;
          text-align: center;
          margin-top: 10px;
        }
      }
      .active {
        background: linear-gradient(90deg, #55618c 0%, #738ddf 100%);
        color: #fff;
      }
    }
    .list {
      flex: 1;
      .list-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        .sort-item {
          margin-right: 20px;
          width: 76px;
          height: 30px;
          font-weight: 400;
          font-size: 14px;
          color: #666666;
          text-align: center;
          line-height: 30px;
          cursor: pointer;
        }
        .sort-active {
          background: #2c3c6f;
          color: #ffffff;
        }
        .header-search {
          width: 420px;
          height: 50px;
          background: #ffffff;
          border-radius: 2px;
          border: 1px solid #e7e7e7;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .header-search-ipt {
            width: 320px;
            height: 100%;
            border: none;
            outline: none;
          }
          .header-search-btn {
            width: 100px;
            height: 50px;
            background: #2c3c6f;
            border-radius: 2px 2px 2px 2px;
            text-align: center;
            line-height: 50px;
            font-weight: 400;
            font-size: 14px;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            img {
              width: 16px;
              height: 16px;
              margin-right: 4px;
            }
          }
        }
      }
      .list-content {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;
        .list-item {
          padding: 4px;
          box-shadow: 0px 4px 4px 0px rgba(18, 74, 163, 0.15);
          border: 1px solid #f6f6f6;
          background-color: #fff;
          cursor: pointer;
          .list-item-img {
            position: relative;
            .isRecommend {
              position: absolute;
              top: 6px;
              left: 6px;
              width: 62px;
              height: 30px;
              border-radius: 15px;
              background-color: #F12F6A;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #fff;
              z-index: 1;
              font-size: 14px;
              img {
                width: 16px;
                height: 16px;
                margin-right: 2px;
              }
            }
            img {
              width: 100%;
              height: 164px;
              object-fit: cover;
              object-position: center;
            }
            .list-item-count {
              position: absolute;
              bottom: 10px;
              left: 10px;
              font-weight: 400;
              font-size: 14px;
              color: #ffffff;
              line-height: 16px;
              > div {
                margin-right: 20px;
              }
              img {
                width: 16px;
                height: 16px;
                margin-right: 4px;
              }
            }
          }
          .list-item-info {
            width: 100%;
            padding: 17px 10px;
            .desc {
              line-height: 30px;
              font-weight: 600;
              font-size: 18px;
              color: #1f1f1f;
              word-break: break-all;
            }
          }
        }
        .list-item:hover {
          animation: scale 0.2s linear forwards;
        }
      }
    }
  }
}
.pagination {
  width: 100%;
  height: 70px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 40px;
  padding: 0 30px;
}
</style>
