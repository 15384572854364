<script setup>
import { ElMessageBox, ElMessage } from "element-plus";
import Breadcrumb from "../../components/breadcrumb/index.vue";
import { getLivePreviewApi, reservationLiveApi, translateLiveApi } from "../../api/live.js";
import { useRoute } from "vue-router";
const route = useRoute();

const id = ref("");
const detailInfo = ref({});

// 获取预告数据
const getLivePreview = () => {
  const params = {
    id: id.value,
    userId: '23143214',
  };
  getLivePreviewApi(params).then((res) => {
    if (res.code === 200) {
      let data = res.data;
      data.livePictureAddress = JSON.parse(data.livePictureAddress)[0].url
      detailInfo.value = data;
    }
  });
};

// 预约直播
const handleAppointment = () => {
  if (detailInfo.value.reservation === 2) {
    ElMessageBox.confirm("确定要取消该直播的预约吗?", "提示", {
      confirmButtonText: "确认",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(() => {
        let params = {
          id: id.value,
          userId: '23143214'
        };
        translateLiveApi(params).then((res) => {
          if (res.code === 200) {
            getLivePreview();
            ElMessage({
              type: "success",
              message: "已取消",
            });
          }
        });
      })
      .catch(() => {});
  } else {
    let params = {
      id: id.value,
      userId: '23143214'
    };
    reservationLiveApi(params).then((res) => {
      if (res.code === 200) {
        getLivePreview();
        ElMessage({
          type: "success",
          message: "预约成功",
        });
      };
    });
  }
};

onMounted(() => {
  id.value = route.query.id;
  getLivePreview();
});
</script>

<template>
  <div class="page-container">
    <Breadcrumb name="直播预告详情"></Breadcrumb>
    <div class="page-content">
      <div class="page-main">
        <div class="page-main-title">{{ detailInfo.title }}</div>
        <div class="page-main-content flexRow">
          <img :src="detailInfo.livePictureAddress" alt="" />
          <div class="page-main-content-info">
            <div class="item flexRow">
              <div class="cube"></div>
              <div class="item-detail">
                <div class="item-detail-label">主播</div>
                <div class="item-detail-val">{{ detailInfo.anchorName }}</div>
              </div>
            </div>
            <div class="item flexRow">
              <div class="cube"></div>
              <div class="item-detail">
                <div class="item-detail-label">直播时间</div>
                <div class="item-detail-val">
                  {{ detailInfo.liveTimeStart }}
                </div>
              </div>
            </div>
            <div class="item flexRow">
              <div class="cube"></div>
              <div class="item-detail">
                <div class="item-detail-label">直播详情</div>
                <div class="item-detail-val">
                  {{ detailInfo.details || "暂无直播详情" }}
                </div>
              </div>
            </div>
            <div
              class="appointment-btn"
              :class="detailInfo.reservation === 2 ? 'isActive' : ''"
              @click="handleAppointment"
            >
              {{ detailInfo.reservation === 2 ? "已预约" : "立即预约" }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.page-container {
  width: 100%;
  margin: 0 auto;
  padding-bottom: 70px;
  max-width: 1920px;
  background: #f5f5f7;
  .page-content {
    width: 1200px;
    margin: 0 auto;
    padding-top: 20px;
    box-sizing: border-box;
    .page-main {
      width: 100%;
      min-height: 550px;
      background-color: #fff;
      padding: 20px 20px 0;
      .page-main-title {
        font-weight: 600;
        font-size: 20px;
        color: #1f1f1f;
        line-height: 23px;
      }
      .page-main-content {
        margin-top: 30px;
        img {
          width: 665px;
          height: 374px;
          margin-right: 20px;
          flex-shrink: 0;
          object-fit: cover;
          object-position: center;
        }
        .page-main-content-info {
          .item {
            margin-bottom: 30px;
            .cube {
              margin-right: 10px;
              margin-top: 4px;
              flex-shrink: 0;
            }
            .item-detail-label {
              font-weight: 600;
              font-size: 16px;
              color: #1f1f1f;
              line-height: 19px;
            }
            .item-detail-val {
              font-weight: 400;
              font-size: 14px;
              color: #1f1f1f;
              line-height: 16px;
              margin-top: 10px;
              padding-right: 20px;
              white-space: pre-wrap;
            }
          }
          .appointment-btn {
            width: 168px;
            height: 44px;
            background: rgba(44, 60, 111, 0);
            border-radius: 2px;
            border: 1px solid #2c3c6f;
            font-weight: 400;
            font-size: 14px;
            color: #2c3c6f;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 20px;
            cursor: pointer;
          }
          .isActive {
            border: none;
            background: #eeeeee;
            color: #666666;
          }
        }
      }
    }
  }
}
</style>
