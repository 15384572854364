import Cookies from 'js-cookie'

const TokenKey = 'Authorization'

const clientKey = 'clientid'

const expireKey = 'expire';

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function setClientKey(clientId) {
  return Cookies.set(clientKey, clientId)
}

export function getClientKey() {
  return Cookies.get(clientKey)
}

export function removeClientKey() {
  return Cookies.remove(clientKey)
}

export function setExpireKey() {
  return Cookies.set(expireKey, time())
}

export function getExpireKey() {
  return Cookies.get(expireKey)
}

export function removeExpireKey() {
  return Cookies.remove(expireKey)
}

const time = () => {
  return Math.round(new Date() / 1000) + 60 * 60 * 24;
};

